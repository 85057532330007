<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        <small v-if="id"> ({{ id }}) </small>
        {{ id ? data.name : "Новая группа доступа" }}
      </template>

      <v-row>
        <v-col cols="12" class="pb-0">
          <a-form-model v-model="data" :model="model" :errors="errors" :config="{ dense: true }" @validate="validate($event)" />
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save @click="submit()"></a-btn-save>
        <a-btn-delete v-if="id" @click="removeDialogShow = true"></a-btn-delete>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess } from "../../components/mixings";
export default {
  mixins: [getForm, submitForm, removeEl, getAccess],
  props: {
    value: Boolean,
    id: Number,
    api: String,
    defaults: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      api1: "/uploads-group",
      m: this.$store.getters["config/get"].models.uploadsGroup,
      removeDialogShow: false,
    };
  },
  created() {},
  watch: {
    value() {
      if (this.value) this.updateData(this.id);
    },
  },
  computed: {
    model() {
      let model = JSON.parse(JSON.stringify(this.m.form));
      model.forEach(el => {
        if (el?.sourceList) {
          el.options = this.m[el.sourceList];
        }
      });
      return model;
    },
  },
  methods: {
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
    },
    afterSave(data, status) {
      if (status) {
        this.$emit("input");
        this.$emit("setUser", data);
      }
    },
  },
};
</script>